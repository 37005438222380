import React, {Component} from "react";
import "./css/Project.css";

class Project extends Component {

    render() {

        const img = this.props.img ?
          <div className="project-image">
          <img className={this.props.noShadow ? "no-shadow" : ""} src={this.props.img} alt="Project"/>
          </div> :
          null;

        const video = this.props.video ?
          <div className="project-image">
          <video height="500px" muted loop autoPlay
                 onClick={event => event.target.play()}>
            <source src={this.props.video + ".webm"} type="video/webm"/>
            <source src={this.props.video + ".MP4"} type="video/mp4"/>
          </video>
          </div> : null;

        const github = this.props.github ?
          <a href={this.props.github} target="_blank" rel="noopener noreferrer" className="link-btn">
          <button className="btn btn-github">View on
            <img className="logo" src="/img/GitHub_Logo.png" alt="GitHub"/>
          </button></a> : null;

        const link = this.props.link ?
          <a href={this.props.link} target="_blank" rel="noopener noreferrer" className="link-btn">
            <button className="btn">{this.props.linkText || "Read More"}</button>
          </a> : null;

        return (
            <div className="project" ref={this.ref} id={this.props.id}>
                <div className="project-body">
                    <div className="project-content">
                        <div className="project-header">
                          <h1>{this.props.title}</h1>
                          <span>{this.props.date}</span>
                        </div>
                        <p>
                          {this.props.text}
                        </p>
                        {this.props.children}
                        {github}
                        {link}
                    </div>
                    {img}
                    {video}
                </div>
            </div>
        )
    }

}

export default Project;
